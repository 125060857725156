import { StyleSheet } from "aphrodite";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import {
  accentColor,
  backgroundColor,
  shadeColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";

export interface IStyle {
  block: any;
  blockForLp: any;
  innerBlock: any;
  title: any;
  intro: any;
  buttonBlock: any;
  button: any;
  buttonLine: any;
  buttonFB: any;
  buttonIcon: any;
  buttonText: any;
  buttonSpace: any;
}

export const ChatEntryStyle: IStyle = StyleSheet.create({
  block: {
    position: "relative",
    backgroundColor: backgroundColor.secondaryLightColor,
  },
  blockForLp: {
    position: "relative",
    backgroundColor: backgroundColor.secondaryLightColor,
    borderTop: "none",
    [moreThanBreakpoint("tablet")]: {
      borderTop: 0,
    },
  },
  innerBlock: {
    maxWidth: "620px",
    margin: "0 auto",
    padding: `2rem ${space.atom}`,
    [moreThanBreakpoint("desktop")]: {
      maxWidth: "580px",
    },
  },
  title: {
    fontSize: "1.1875rem",
    lineHeight: lineHeight.headline2,
    padding: space.atom,
    textAlign: "center",
    fontWeight: fontWeight.bold,
    letterSpacing: "0.05em",
    color: textColor.primaryDarkColor,
    [moreThanBreakpoint("tablet")]: {
      fontSize: fontSize.title,
      lineHeight: lineHeight.title,
    },
  },
  intro: {
    fontSize: fontSize.body,
    lineHeight: lineHeight.body1,
    padding: space.atom,
    color: textColor.secondaryDarkColor,
  },
  buttonBlock: {
    textAlign: "center",
    padding: `${space.atom2x} ${space.atom} ${space.atom}`,
    margin: "0 auto",
    maxWidth: "460px",
    [moreThanBreakpoint("tablet")]: {
      maxWidth: "100%",
    },
  },
  button: {
    boxSizing: "border-box",
    width: "240px",
    height: "50px",
    textAlign: "center",
    position: "relative",
    paddingLeft: "50px",
    marginBottom: space.atom,
    color: "#fff",
    fontSize: fontSize.mediumHeading,
    letterSpacing: "0.04em",
    transition: "background 0.4s ease",
    textDecoration: "none",
    display: "inline-block",
    borderRadius: borderRadius.normal,
    ":last-child": {
      marginBottom: 0,
      [moreThanBreakpoint("tablet")]: {
        marginRight: 0,
      },
    },
    ":after": {
      content: '""',
      display: "block",
      height: "100%",
      width: "1px",
      background: "rgba(255,255,255,0.5)",
      position: "absolute",
      top: "0",
      left: "50px",
    },
    [moreThanBreakpoint("tablet")]: {
      marginRight: space.atom2x,
      marginBottom: 0,
    },
  },
  buttonLine: {
    backgroundColor: accentColor.lineColor,
    ":hover": {
      backgroundColor: shadeColor(accentColor.lineColor, -10),
    },
  },
  buttonFB: {
    backgroundColor: accentColor.fbColor,
    ":hover": {
      backgroundColor: shadeColor(accentColor.fbColor, -15),
    },
  },
  buttonIcon: {
    fontSize: "26px",
    position: "absolute",
    left: "13px",
    top: "50%",
    marginTop: "-13px",
  },
  buttonText: {
    display: "inline-block",
    lineHeight: "50px",
  },
  buttonSpace: {
    height: "50px",
  },
});
