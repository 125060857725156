import React, { useState } from "react";
import { stringify } from "qs";
import { useRouter } from "next/router";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { css } from "@emotion/react";
import { decamelize, decamelizeKeys } from "humps";
import { CabinClassBlock } from "main/javascripts/components/flight/form/CabinClassBlock";
import { FlightOriginDestinationDate } from "main/javascripts/components/flight/FlightOriginDestinationDate";
import { ButtonWithLoader } from "main/javascripts/components/button/ButtonWithLoader";
import { GuestsBlock } from "main/javascripts/components/hotel/GuestsBlock";
import { useAppDispatch } from "main/javascripts/store";
import { debouncedFetchFlightDestinationSuggestions } from "../../flight/flightSuggestionSlice";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { maxWidth } from "main/javascripts/styles/base/maxWidthStyle";
import {
  convertSearchDynamicPackageFormValuesToParams,
  postSearchDynamicPackageCache,
} from "../../dynamicPackage/searchDynamicPackageSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { dpAvailabilitiesEvent } from "main/javascripts/utils/googleTagManagerUtil";
import {
  FormNamespaces,
  yupDefaultMessages,
} from "main/javascripts/constants/FormConstants";
import { FlightTypes } from "main/javascripts/constants/FlightTypes";
import { convertServerErrorToForm } from "main/javascripts/utils/FormUtil";
import { FormErrorsBlock } from "main/javascripts/components/form/FormErrorsBlock";

const defaultValues = {
  departure_date: null, // undefinedではダメ
  return_date: null, // undefinedではダメ
  access_type: "direct_web",
  flight_type: decamelize("roundTrip"),
  cabin_class: "Y",
  itineraries: [{ departure_date: null }, { departure_date: null }],
  number_of_rooms: 1,
  guests: [
    {
      number_of_adult: 2,
      number_of_children: 0,
      ages_of_children: [],
    },
  ],
};

type SearchDynamicPackageFormData = {
  form?: any; // エラー用
  flight_type: string;
  origin: string;
  origin_id: number;
  origin_type: string;
  destination: string;
  destination_id: number;
  destination_type: string;
  departure_date: string;
  return_date: string;
  itineraries: any[];
  guests: any[];
  number_of_rooms: number;
};

yup.setLocale(yupDefaultMessages);
const schema = yup.object().shape({
  origin: yup
    .string()
    .nullable()
    .when("flight_type", {
      is: (val) => val !== FlightTypes.multiCity,
      then: yup.string().nullable().required(),
    }),
  origin_id: yup
    .number()
    .nullable()
    .transform((_, val) => (val ? Number(val) : null))
    .when("flight_type", {
      is: (val) => val !== FlightTypes.multiCity,
      then: yup.number().nullable().required(),
    }),
  destination: yup
    .string()
    .nullable()
    .when("flight_type", {
      is: (val) => val !== FlightTypes.multiCity,
      then: yup.string().nullable().required(),
    }),
  destination_id: yup
    .number()
    .nullable()
    .transform((_, val) => (val ? Number(val) : null))
    .when("flight_type", {
      is: (val) => val !== FlightTypes.multiCity,
      then: yup.number().nullable().required(),
    }),
  departure_date: yup
    .string()
    .nullable()
    .when("flight_type", {
      is: (val) => val !== FlightTypes.multiCity,
      then: yup.string().nullable().required(),
    }),
  return_date: yup
    .string()
    .nullable()
    .when("flight_type", {
      is: (val) => val === FlightTypes.roundTrip,
      then: yup.string().nullable().required(),
    }),
  itineraries: yup
    .array()
    .nullable()
    .when("flight_type", {
      is: (val) => val === FlightTypes.multiCity,
      then: (schema) =>
        schema.of(
          yup.object().shape({
            origin: yup.string().required(),
            origin_id: yup.number().required(),
            destination: yup.string().required(),
            destination_id: yup.number().required(),
            departure_date: yup.string().nullable().required(),
          })
        ),
    }),
  guests: yup.array().of(
    yup.object().shape({
      number_of_children: yup
        .number()
        .nullable()
        .transform((_, val) => (val || val === 0 ? Number(val) : null))
        .required(),
      ages_of_children: yup.array().when("number_of_children", {
        is: (val) => Number(val) > 0,
        then: yup.array().of(
          yup.object().shape({
            age: yup
              .number()
              .nullable()
              .transform((_, val) => (val || val === 0 ? Number(val) : null))
              .required(),
          })
        ),
      }),
    })
  ),
});

export const SearchDynamicPackage = (): React.ReactElement => {
  const router = useRouter();
  const appDispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm<SearchDynamicPackageFormData>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    clearErrors("form");
    handleSubmit(onSubmit)(e);
  };

  const onSubmit: (values: any) => void = async (values: any) => {
    setIsSubmitting(true);
    const params: any = convertSearchDynamicPackageFormValuesToParams(values);
    return appDispatch(postSearchDynamicPackageCache({ params }))
      .then(unwrapResult)
      .then((result) => {
        dpAvailabilitiesEvent(result.data.dynamicPackageId, params);
        router.push(`/flights?${stringify(
          decamelizeKeys(result.data.conditions),
          { arrayFormat: "indices" }
        )}
&cache_id=${result.data.cacheId}
&dynamic_package_id=${result.data.dynamicPackageId}&search_group_id=${
          result.data.searchGroupId
        }&search_type=dp`);
      })
      .catch((errorResponse) => {
        setIsSubmitting(false);
        if (errorResponse.errors) {
          // エラー形式を合わせるために変換している
          const errors = Object.keys(errorResponse.errors).map((key) => {
            return { [key]: errorResponse.errors[key] };
          });
          convertServerErrorToForm({ fieldMessages: errors }, setError);
        }
      });
  };

  const fetchFlightDestinationSuggestionsAction = (data: any, type: string) => {
    const { text, ...rest } = data;
    appDispatch(
      debouncedFetchFlightDestinationSuggestions({
        params: { text: text, ...rest },
        type,
      })
    );
  };

  return (
    <div css={blockStyle}>
      <FormProvider {...methods}>
        <form onSubmit={handleFormSubmit}>
          <div css={firstBlockStyle}>
            <FlightOriginDestinationDate
              destinationSuggestOptions={{}}
              fetchFlightOriginDestinationSuggestions={
                fetchFlightDestinationSuggestionsAction
              }
              useDatePicker={true}
              labelColorStyleKey="default"
              borderColorStyleKey="secondary"
            />
          </div>
          <div css={flightClassBlockStyle}>
            <CabinClassBlock
              labelColorStyleKey="default"
              borderColorStyleKey="secondary"
            />
          </div>
          <GuestsBlock
            labelColorStyleKey="default"
            borderColorStyleKey="secondary"
          />
          {errors && (
            <div>
              <FormErrorsBlock
                namespace={FormNamespaces.flight}
                errors={errors}
                hideValidationErrors={true}
              />
            </div>
          )}
          <div css={submitBlockStyle}>
            <ButtonWithLoader
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              検 索
            </ButtonWithLoader>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const submitBlockStyle = css`
  padding: ${space.atom} 0 ${space.atom2x};

  ${moreThanBreakpoint("desktop")} {
    padding-top: ${space.atom2x};
    width: 50%;
    margin-left: auto;
    margin-right: 0;
  }
`;
const blockStyle = css`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 ${space.atom};

  ${moreThanBreakpoint("tablet")} {
    padding: 0 ${space.atom};
    margin: ${space.atom} auto ${space.atom2x};
  }

  ${moreThanBreakpoint("desktop")} {
    box-sizing: border-box;
    max-width: ${maxWidth.page1colWide};
  }
`;
const firstBlockStyle = css`
  padding: ${space.atom} 0 0;
`;
const flightClassBlockStyle = css`
  width: 50%;
  min-width: 200px;
`;
