import React, { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useForm, FormProvider } from "react-hook-form";
import { stringify } from "qs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { css } from "@emotion/react";
import { useRouter } from "next/router";
import {
  convertSearchHotelFormValuesToParams,
  postSearchHotelCache,
} from "main/javascripts/features/hotel/searchHotelSlice";
import { GuestsBlock } from "main/javascripts/components/hotel/GuestsBlock";
import { HotelDestinationBlock } from "main/javascripts/components/hotel/HotelDestinationBlock";
import { ButtonWithLoader } from "main/javascripts/components/button/ButtonWithLoader";
import { HotelDatePickerBlock } from "main/javascripts/components/hotel/HotelDatePickerBlock";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { useAppDispatch } from "main/javascripts/store";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { maxWidth } from "main/javascripts/styles/base/maxWidthStyle";
import { debouncedFetchHotelDestinationSuggestions } from "main/javascripts/features/hotel/hotelSuggestionSlice";
import {
  FormNamespaces,
  yupDefaultMessages,
} from "main/javascripts/constants/FormConstants";
import { convertServerErrorToForm } from "main/javascripts/utils/FormUtil";
import { hotelAvailabilitiesEvent } from "main/javascripts/utils/googleTagManagerUtil";
import { FormErrorsBlock } from "main/javascripts/components/form/FormErrorsBlock";

type SearchHotelFormData = {
  form?: any; // エラー用
  destination: string;
  checkin_date: string;
  checkout_date: string;
  guests: any[];
  number_of_rooms: number;
};

yup.setLocale(yupDefaultMessages);
const schema = yup.object().shape({
  destination: yup.string().nullable().required(),
  destination_id: yup
    .number()
    .nullable()
    .transform((_, val) => (val ? Number(val) : null))
    .required(),
  checkin_date: yup.string().nullable().required(),
  checkout_date: yup.string().nullable().required(),
  guests: yup.array().of(
    yup.object().shape({
      number_of_children: yup
        .number()
        .nullable()
        .transform((_, val) => (val || val === 0 ? Number(val) : null))
        .required(),
      ages_of_children: yup.array().when("number_of_children", {
        is: (val) => Number(val) > 0,
        then: yup.array().of(
          yup.object().shape({
            age: yup
              .number()
              .nullable()
              .transform((_, val) => (val || val === 0 ? Number(val) : null))
              .required(),
          })
        ),
      }),
    })
  ),
});

const defaultValues = {
  destination: "",
  checkin_date: null, // undefinedではダメ
  checkout_date: null, // undefinedではダメ
  number_of_rooms: 1,
  guests: [
    {
      number_of_adult: 2,
      number_of_children: 0,
      ages_of_children: [],
    },
  ],
};

export const SearchHotel = (): React.ReactElement => {
  const router = useRouter();
  const appDispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm<SearchHotelFormData>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    clearErrors("form");
    handleSubmit(onSubmit)(e);
  };

  const onSubmit: (values: any) => void = async (values: any) => {
    const submitValues = convertSearchHotelFormValuesToParams(values);
    setIsSubmitting(true);
    return appDispatch(postSearchHotelCache({ params: submitValues }))
      .then(unwrapResult)
      .then((result) => {
        const cacheId = result.data.cacheId;
        hotelAvailabilitiesEvent(cacheId, submitValues);
        router.push(
          `/hotels?${stringify(submitValues, {
            arrayFormat: "indices",
          })}&cache_id=${cacheId}`
        );
      })
      .catch((errorResponse) => {
        setIsSubmitting(false);
        if (errorResponse.errors) {
          // エラー形式を合わせるために変換している
          const errors = Object.keys(errorResponse.errors).map((key) => {
            return { [key]: errorResponse.errors[key] };
          });
          convertServerErrorToForm({ fieldMessages: errors }, setError);
        }
      });
  };

  const fetchHotelDestinationSuggestionsAction = (data: any) => {
    const { text, ...rest } = data;
    appDispatch(
      debouncedFetchHotelDestinationSuggestions({
        params: { text: text, ...rest },
      })
    );
  };

  return (
    <div css={blockStyle}>
      <FormProvider {...methods}>
        <form onSubmit={handleFormSubmit}>
          <div css={firstBlockStyle}>
            <div css={destBlockStyle}>
              <HotelDestinationBlock
                fetchHotelDestinationSuggestions={
                  fetchHotelDestinationSuggestionsAction
                }
                suggestOptions={{
                  excluded_code: "JP",
                }}
                labelColorStyleKey="default"
                borderColorStyleKey="secondary"
              />
            </div>
            <HotelDatePickerBlock borderColorStyleKey="secondary" />
          </div>
          <GuestsBlock
            labelColorStyleKey="default"
            borderColorStyleKey="secondary"
          />
          {errors && (
            <div>
              <FormErrorsBlock
                namespace={FormNamespaces.hotel}
                errors={errors}
                hideValidationErrors={true}
              />
            </div>
          )}
          <div css={submitBlockStyle}>
            <ButtonWithLoader
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              検 索
            </ButtonWithLoader>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const submitBlockStyle = css`
  padding: ${space.atom} 0 ${space.atom2x};
  ${moreThanBreakpoint("desktop")} {
    padding-top: ${space.atom2x};
    width: 50%;
    margin-left: auto;
    margin-right: 0;
  }
`;
const blockStyle = css`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 ${space.atom};
  ${moreThanBreakpoint("tablet")} {
    padding: 0 ${space.atom};
    margin: ${space.atom} auto ${space.atom2x};
  }
  ${moreThanBreakpoint("desktop")} {
    box-sizing: border-box;
    max-width: ${maxWidth.page1colWide};
  }
`;
const firstBlockStyle = css`
  padding: ${space.atom} 0 0;
  ${moreThanBreakpoint("desktop")} {
    display: flex;
  }
`;
const destBlockStyle = css`
  flex: 1;
`;
