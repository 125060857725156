import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { camelizeKeys, decamelizeKeys } from "humps";
import { createWrapper } from "main/javascripts/api/AxiosWrapper";
import { ErrorResponse } from "main/javascripts/types/errorResponse";
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "main/javascripts/utils/sliceUtil";
import { SearchDynamicPackage } from "main/javascripts/types/searchDynamicPackage";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";
import { clone } from "../../../../manager/javascripts/utils/ObjectUtil";
import { FlightTypes } from "../../constants/FlightTypes";
import { getUtmValues } from "../../utils/googleAnalyticsUtil";

const key = "searchDynamicPackage";

const initialState: SearchDynamicPackage = {
  loading: false,
  errors: null,
};

/** Async **/
export const postSearchDynamicPackageCache = createAsyncThunk<
  {
    data: any;
  },
  {
    params?: any;
  },
  {
    rejectValue: ErrorResponse;
  }
>(`${key}/postSearchDynamicPackageCache`, async (args, { rejectWithValue }) => {
  try {
    const { params } = args;
    const url = `${PATH_SCOPE}/api/search_dynamic_package/cache`;
    const utmValues = getUtmValues();
    const requestParams = {
      ...utmValues,
      ...params,
    };
    const result = await createWrapper().post(
      url,
      decamelizeKeys({ conditions: requestParams })
    );
    return {
      data: result.data,
    };
  } catch (err) {
    return rejectWithValue(camelizeKeys(err.response.data));
  }
});

export const postResearchDynamicPackageCache = createAsyncThunk<
  {
    data: any;
  },
  {
    cacheId: string;
  },
  {
    rejectValue: ErrorResponse;
  }
>(
  `${key}/postResearchDynamicPackageCache`,
  async (args, { rejectWithValue }) => {
    try {
      const { cacheId } = args;
      const url = `/api/research_dynamic_package/cache.json`;
      const result = await createWrapper().post(
        url,
        decamelizeKeys({ cacheId })
      );
      console.log(result);
      return {
        data: result.data,
      };
    } catch (err) {
      return rejectWithValue(camelizeKeys(err.response.data));
    }
  }
);

/** slice **/
export const searchDynamicPackageSlice = createSlice({
  name: key,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // 一旦slice単位で共通化
      .addMatcher(isPendingAction(key), (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addMatcher(isFulfilledAction(key), (state) => {
        state.loading = false;
      })
      .addMatcher(isRejectedAction(key), (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      });
  },
});

export const convertSearchDynamicPackageFormValuesToParams = (data: any) => {
  const params = clone(data);
  if (params.flight_type === FlightTypes.multiCity) {
    delete params.departure_date;
    delete params.return_date;
  } else if (params.flight_type === FlightTypes.oneWay) {
    delete params.return_date;
    delete params.itineraries;
  } else {
    delete params.itineraries;
  }
  if (params.guests?.length > 0) {
    params.guests.map((guest, index) => {
      if (Number(guest.number_of_children) > 0) {
        params.guests[index].ages_of_children = guest.ages_of_children.map(
          (age) => age.age
        );
      } else {
        delete params.guests[index].ages_of_children;
      }
    });
  }
  return params;
};
