import { connect } from "react-redux";

import {
  ChatEntry,
  IProps,
} from "main/javascripts/components/organisms/ChatEntry";
import { ReduxState } from "main/javascripts/reducers/";
import {
  getAvailableFacebookMessengerRegisterUrl,
  getAvailableLineRegisterUrl,
} from "main/javascripts/selectors/AccountParamsSelector";

const mapStateToProps: any = (state: ReduxState): IProps => {
  return {
    facebookMessengerRegisterUrl:
      getAvailableFacebookMessengerRegisterUrl(state),
    lineRegisterUrl: getAvailableLineRegisterUrl(state),
  };
};

export const ChatEntryContainer: any = connect<IProps, any, any>(
  mapStateToProps,
  {}
)(ChatEntry);
