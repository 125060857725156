import * as React from "react";
import { css } from "aphrodite";
import { ChatEntryStyle } from "./ChatEntryStyle";
import { Icon } from "../../atoms/Icon";
import { BasicBlock } from "../../atoms/Block/BasicBlock";

export interface IProps {
  styles?: any;
  facebookMessengerRegisterUrl: string | null;
  lineRegisterUrl: string | null;
}

export class ChatEntry extends React.Component<IProps> {
  public render(): JSX.Element | null {
    const {
      styles = {},
      facebookMessengerRegisterUrl,
      lineRegisterUrl,
    } = this.props;
    const { block, title, intro, buttonFB, buttonLine } = styles;
    return (
      <BasicBlock
        styleKey="blockFullWidth"
        styles={[ChatEntryStyle.block, block]}
      >
        <BasicBlock styles={ChatEntryStyle.innerBlock}>
          <div className={css(ChatEntryStyle.title, title)}>
            経験豊富な旅のプロが
            <br />
            チャットであなたの旅行をサポート
          </div>
          <div className={css(ChatEntryStyle.intro, intro)}>
            たびチャットとは、いつでもどこでも気軽に相談できるチャット版の旅行代理店です。
            <br />
            旅行の予約だけでなく、必要な申請・準備・現地サポートと、旅行終了まで旅のプロがチャットでサポートします。
          </div>
          {facebookMessengerRegisterUrl || lineRegisterUrl ? (
            <div className={css(ChatEntryStyle.buttonBlock)}>
              {facebookMessengerRegisterUrl ? (
                <a
                  className={css(
                    ChatEntryStyle.button,
                    ChatEntryStyle.buttonFB,
                    buttonFB
                  )}
                  href={facebookMessengerRegisterUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    styleKey="facebook"
                    styles={ChatEntryStyle.buttonIcon}
                  />
                  <span className={css(ChatEntryStyle.buttonText)}>
                    Facebookで相談
                  </span>
                </a>
              ) : (
                ""
              )}
              {lineRegisterUrl ? (
                <a
                  className={css(
                    ChatEntryStyle.button,
                    ChatEntryStyle.buttonLine,
                    buttonLine
                  )}
                  href={lineRegisterUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon styleKey="line" styles={ChatEntryStyle.buttonIcon} />
                  <span className={css(ChatEntryStyle.buttonText)}>
                    LINE 友だちに追加
                  </span>
                </a>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className={css(ChatEntryStyle.buttonBlock)}>
              <div className={css(ChatEntryStyle.buttonSpace)} />
            </div>
          )}
        </BasicBlock>
      </BasicBlock>
    );
  }
}
