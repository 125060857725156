import { createSelector } from "reselect";
import { ReduxState } from "main/javascripts/reducers/";
import { getLocale } from "./LocaleSelector";

const getUtmSource: any = (state: ReduxState): any =>
  state.accountParamsReducer.utmSource;
const getLineRegisterUrl: (state: ReduxState) => any = (
  state: ReduxState
): any => state.accountParamsReducer.lineRegisterUrl;

export const getAvailableLineRegisterUrl: any = createSelector(
  getUtmSource,
  getLineRegisterUrl,
  getLocale,
  (utmSource: any, lineRegisterUrl: any, locale: string): string | null => {
    // 日本以外サポート外
    if (locale !== "ja") {
      return null;
    }
    if ((utmSource === "line" || !utmSource) && lineRegisterUrl) {
      return lineRegisterUrl;
    }
    return null;
  }
);

export const getAvailableFacebookMessengerRegisterUrl: any = createSelector(
  getUtmSource,
  (utmSource: any): string | null => {
    if (utmSource === "facebook") {
      return "https://m.me/tabitabi.official";
    }
    return null;
  }
);

export const getResearchFlightLiffId: any = (state: ReduxState): any =>
  state.accountParamsReducer.researchFlightLiffId;
export const getResearchHotelLiffId: any = (state: ReduxState): any =>
  state.accountParamsReducer.researchHotelLiffId;
